<template>
 
    <NuxtLink 
      class="contest" 
      :to="'/giveaways/' + link"
      :style="'background-image: url(' + cover + ');'">   
      <div class="inf">
        <div class="nm">{{ name }}</div>
      </div>   
      <div v-if="isclosed" class="completed"><span>{{ $t('giveaways_view_completed_big') }}</span></div>
    </NuxtLink>
 
  
</template>

<script>
 

export default {   
  props: {
    name: {
      type: String
    },
    link: {
      type: String
    },
    isopened: {
      type: String
    },
    isclosed: {
      type: String
    },
    cover: {
      type: String
    },
  },
  data () {
    return {            
    }
  },
  created() {    
    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
  


.contest {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  height: 280px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  width: calc(100%/4 - 1rem);
  /* min-width: 170px; */
  margin-right: 1rem;
  border-radius: 10px;
}

@media (min-width: 100px) {
  .contest { 
    width: calc(100%/2 - 1rem);
    min-width: calc(100%/2 - 1rem); 
  }
}


@media (min-width: 768px) {
  .contest { 
    width: calc(100%/3 - 1rem);
    min-width: calc(100%/3 - 1rem); 
  }
}


@media (min-width: 890px) {
  .contest { 
    width: calc(100%/4 - 1rem);
    min-width: calc(100%/4 - 1rem); 
  }
}

@media (min-width: 1280px) {
  .contest { 
    width: calc(100%/5 - 1rem);
    min-width: calc(100%/5 - 1rem); 
  }
}
@media (min-width: 1465px) {
  .contest { 
    width: calc(100%/6 - 1rem);
    min-width: calc(100%/6 - 1rem); 
  }
}
@media (min-width: 1653px) {
  .contest { 
    width: calc(100%/7 - 1rem);
    min-width: calc(100%/7 - 1rem); 
  }
} 


 

.contest .cover {
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  margin-right: 15px;
  border-radius: 10px;
}

.contest .inf {
  background-color:var(--un-background-color);
  width: 90%;
  border-radius: 10px;
  left: 5%;
  position: absolute;
  padding: 7px 13px;
  bottom: 10px;
  box-shadow: 0px 0px 10px #00000047;
}

.contest .inf .nm {
  font-weight: bold;
  line-height: 1.3rem;
}

@media (max-width: 768px) {


  .contest .list{
    height: 220px;
  }

  .contest {
    /* height: 210px; */
  }

  .contest {
    /* height: auto; */
    padding: 1.5rem 1.5rem;
    padding-bottom: 0.5rem;
  }

}

@media (max-width: 400px) {


    .contest {
      width: calc(50% - 1rem);
      min-width: calc(50% - 1rem);
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }


}


</style>
